<template>
  <div>
    <el-table v-if="data.length" :data="data">
      <el-table-column>
        <template slot="header">
          <span> Name </span>
        </template>
        <template v-slot="scope">
          <span
            class="text-primary text-cursor"
            @click="
              $router.push({
                name: 'customers.individual',
                params: { id: scope.row.customer_id },
              })
            "
          >
            {{ formatName(scope.row.customer_name) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span> Date Signed Up </span>
        </template>
        <template v-slot="scope">
          <p>{{ formatDate(scope.row.signup_date, "do m, y") }}</p>
        </template>
      </el-table-column>
    </el-table>
    <eden-content-empty v-else :text="'No referred signups'" />
  </div>
</template>

<script>
export default {
  name: "CustomerReferredSignups",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
