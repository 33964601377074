<template>
  <div>
    <el-table v-if="data.length" :data="data">
      <el-table-column>
        <template slot="header">
          <span> Reward/Code </span>
        </template>
        <template v-slot="scope">
          <p>{{ scope.row.title }}</p>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span> Discount Amount </span>
        </template>
        <template v-slot="scope">
          <p v-if="scope.row.discount.discount_type === 'fixed-amount'">-{{ formatPrice(scope.row.discount.discount_value) }}</p>
          <p v-else-if="scope.row.discount.discount_type === 'percentage'">-{{ scope.row.discount.discount_value || '-'}}% off</p>
          <p v-else>{{scope.row.discount.discount_value || '-'}}</p>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span> Date Redeemed </span>
        </template>
        <template v-slot="scope">
          <p>{{ formatDate(scope.row.date_redeemed, "do m, y") }}</p>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span> Status </span>
        </template>
        <template v-slot="scope">
          <el-tag
              :type="setType(scope.row.status)"
              >{{ formatText(scope.row.status) }}</el-tag
            >
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span class="eden-table-header">
            Date Applied/Used
            <i class="el-icon-bottom"></i>
          </span>
        </template>
        <template v-slot="scope">
          <p v-if="scope.row.dates_used.length">
            <span v-if="scope.row.dates_used.length === 1">
              {{ formatDate(scope.row.dates_used[0], "do m, y") }}
            </span>
            <span class="text-primary text-cursor text-underline"  @click="showDates(scope.row)" v-else>
              View details
            </span>
          </p>
          <p v-else class="text-orange">-</p>
        </template>
      </el-table-column>
    </el-table>
    <eden-content-empty v-else :text="'No redeemed rewards'" />
    <discount-customer-breakdown
      :display-dates="true"
      :show.sync="showDatesUsed"
      :customer="customer"
    />
  </div>
</template>

<script>
import DiscountCustomerBreakdown from "@/components/DiscountsAndRewards/Discounts/Discount/DiscountCustomerBreakdown";

export default {
  name: "CustomerRedeemedRewards",
  components: {
    DiscountCustomerBreakdown,
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data(){
    return{
      showDatesUsed:false,
      customer:{}
    }
  },
  methods:{
    showDates(customer){
      this.showDatesUsed = true
      this.customer.discount_used_timestamps = customer.dates_used
    }
  }
};
</script>
