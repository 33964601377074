<template>
  <div>
    <el-table v-if="data.length" :data="data">
      <el-table-column>
        <template slot="header">
          <span> Badge </span>
        </template>
        <template v-slot="scope">
          <div class="is-flex is-align-center">
            <div class="badge-image">
              <img
                v-if="scope.row.badge_image"
                :src="getThumbnailFromCloudinary(scope.row.badge_image)"
                alt="Image"
              />
            </div>
            <div>
              <p class="text-bold">{{ scope.row.name }}</p>
              <span>{{ scope.row.badge_description }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span> Unlocked On </span>
        </template>
        <template v-slot="scope">
          <p class="text-black">
            {{ formatDate(scope.row.date_unlocked, "do m, y") }}
          </p>
        </template>
      </el-table-column>
    </el-table>
    <eden-content-empty v-else :text="'No unlocked badges'" />
  </div>
</template>

<script>
export default {
  name: "CustomerBadges",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped lang="scss">
.badge-image {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background: #798b83;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 100%;
    background: #798b83;
  }
}
</style>
