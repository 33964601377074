<template>
  <div>
    <el-table v-if="data.length" :data="data">
      <el-table-column>
        <template slot="header">
          <span> Name </span>
        </template>
        <template v-slot="scope">
          <p
            @click="
              $router.push({
                name: 'customers.individual',
                params: { id: scope.row.customer_id },
              })
            "
          >
            {{ formatName(scope.row.customer_name) }}
          </p>
        </template>
      </el-table-column>
      <!-- <el-table-column>
        <template slot="header">
          <span> Email </span>
        </template>
        <template v-slot="scope">
          <p class="text-black">
            {{ scope.row.email || "-" }}
          </p>
        </template>
      </el-table-column> -->
      <el-table-column>
        <template slot="header">
          <span> Date Subscribed </span>
        </template>
        <template v-slot="scope">
          <p>{{ formatDate(scope.row.subscription_date, "do m, y") }}</p>
        </template>
      </el-table-column>
    </el-table>
    <eden-content-empty v-else :text="'No referred subscriptions'" />
  </div>
</template>

<script>
export default {
  name: "CustomerReferredSubscriptions",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
