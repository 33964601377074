<template>
  <div>
    <eden-page-header
      :title="'Customers'"
      :subtitle="customer.name"
      :section="'Referrals & Reward Centre'"
    />
    <eden-information-card>
      <template slot="content">
        <div class="customer-reward">
          <div class="customer-reward--summary">
            <div class="name">
              <eden-image
                :image-url="customer.avatar"
                :image-size="32"
                :image-type="'circle'"
              />
              <p
                class="text-primary text-cursor"
                @click="
                  $router.push({
                    name: 'customers.individual',
                    params: { id: customerId },
                  })
                "
              >
                {{ formatName(customer.name) }}
              </p>
            </div>
            <div class="code">
              <span>Referral code</span>
              <p>{{ data.code }}</p>
            </div>
          </div>
          <div class="customer-reward--stats">
            <div v-for="(stat, index) in stats" :key="index">
              <h6>{{ stat.figure }}</h6>
              <p>{{ stat.label }}</p>
            </div>
          </div>
        </div>
      </template>
    </eden-information-card>
    <el-tabs v-model="tab">
      <el-tab-pane label="Redeemed Rewards" name="rewards">
        <customer-redeemed-rewards :data="data.rewards" />
      </el-tab-pane>
      <el-tab-pane label="Unlocked Badges" name="badges">
        <customer-badges :data="data.badges" />
      </el-tab-pane>
      <el-tab-pane label="Referred Signups" name="signups">
        <customer-referred-signups :data="data.signups" />
      </el-tab-pane>
      <el-tab-pane label="Referred Subscribers" name="subscribers">
        <customer-referred-subscribers :data="data.subscribers" />
      </el-tab-pane>
    </el-tabs>
    <div></div>
  </div>
</template>

<script>
import CustomerRedeemedRewards from "@/components/Customers/Individual/Rewards/CustomerRedeemedRewards";
import CustomerBadges from "@/components/Customers/Individual/Rewards/CustomerBadges";
import CustomerReferredSignups from "@/components/Customers/Individual/Rewards/CustomerReferredSignups";
import CustomerReferredSubscribers from "@/components/Customers/Individual/Rewards/CustomerReferredSubscribers";

import customer from "@/requests/customers/customer";

export default {
  name: "CustomerRewardsCentre",
  components: {
    CustomerRedeemedRewards,
    CustomerBadges,
    CustomerReferredSignups,
    CustomerReferredSubscribers,
  },
  data() {
    return {
      tab: "rewards",
      customer: {
        avatar: "",
        name: "",
      },
      data: {
        code: "",
        apples: 0,
        rewards: [],
        badges: [],
        signups: [],
        subscribers: [],
      },
    };
  },
  computed: {
    customerName() {
      return "Cjay Thompson";
    },
    customerId() {
      return this.$route.params.id;
    },
    stats() {
      return [
        {
          label: "Apples",
          figure: this.data.apples,
        },
        {
          label: "Badges Unlocked",
          figure: this.data.badges.length,
        },
        {
          label: "Referred Signups",
          figure: this.data.signups.length,
        },
        {
          label: "Referred Subscriptions",
          figure: this.data.subscribers.length,
        },
      ];
    },
  },
  created() {
    this.getRewards(true);
  },
  methods: {
    getRewards(refresh) {
      this.loading = true;
      customer
        .rewards(this.customerId, refresh)
        .then((response) => {
          const { status, data } = response.data;

          if (status) {
            this.data.code = data.referral_code;
            this.data.apples = data.apples_count;
            this.data.rewards = data.redeemed_rewards;
            this.data.badges = data.badges;
            this.data.signups = data.referred_signups;
            this.data.subscribers = data.referred_subscribers;

            this.customer.avatar = data.avatar;
            this.customer.name = data.name;

            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.information-card {
  padding: 0;
  margin-bottom: 40px;
}

.customer-reward {
  display: flex;
  align-items: center;

  &--summary {
    padding: 16px 0;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--eden-grey-septenary);

    .name {
      display: flex;
      align-items: center;

      p {
        margin-left: 7px;
        font-weight: 500;
      }
    }

    .code {
      margin-top: 15px;
      text-align: center;

      span {
        color: var(--eden-grey-tertiary);
        font-size: 0.875rem;
      }

      p {
        margin-top: 5px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }

  &--stats {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 65%;

    > div {
      text-align: center;

      p {
        margin-top: 10px;
        font-size: 0.9rem;
        color: var(--eden-grey-tertiary);
      }
    }
  }

  @media (max-width: 1300px) {
    flex-wrap: wrap;
  }
}
</style>
